<template>
    <div class="form">
        <div v-if="!formSended">
            <h1 class="form__page-name">Пожалуйста, введите данные чтобы отправить запрос.</h1>
            <h5 class="form__page-description">Организатор тура свяжется с вами, ответит на все вопросы и предоставит подробную программу тура</h5>
            <div class="form__close" @click="close"></div>
            <ValidationObserver ref="validator" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.name"
                        placeholder="Ваше имя"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.phone"
                        placeholder="Телефон"
                        :error-messages="errors[0]"
                        type="tel"
                        autocomplete="off"
                    />
                </ValidationProvider>
                <BaseTextField
                    v-model="feedback.email"
                    placeholder="Ваша почта"
                />
                <BaseButton
                    class="button-fill form__save"
                    @click="save()">{{ text }}</BaseButton>
                <div class="form__personal-info">
                    Нажимаю кнопку подтверждаю согласие c Пользовательским соглашением и Политикой конфиденциальности
                </div>
            </ValidationObserver>
        </div>
        <div v-if="formSended" class="form__sended">
            <div class="form__sended__title">Спасибо за вашу заявку!</div>
            <div class="form__close" @click="close"></div>
            <div class="form__sended__text">
                Ваша заявка принята. В ближайшее время с вами свяжется организатор путешествия. 
                Он сможет предоставить детальную информацию по туру и ответить на любые вопросы. 
                Команда GetAdventutres</div>
            <BaseButton
                class="button-fill"
                style="width:300px"
                @click="close"
            >Хорошо</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from 'moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'

    export default {
        name: 'PopupForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
                personalDataAgree: true,
            },
            formSended: false,
        }),
        props: {
            text: '',
            comment: '',
        },
        computed: {
            ...mapState('trips', {
                trip: state => state.entity
            }),
        },
        async mounted() {
        },
        methods: {
            async save() {
                this.feedback.link = document.location.href;
                this.feedback.comment = this.comment;
                const valid = await this.$refs.validator.validate()
                if(valid) {
                    this.$emit('save', this.feedback);
                    await this.formReverse()
                    await this.clear()
                }
            },
            clear() {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                }
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            close() {
                this.$emit('close')
                this.formSended = false
            }
        }
    }
</script>

<style lang="scss">
    .form {
        position: relative;
        max-width: 600px;
        padding: 50px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
            border-radius: 12px;
        }
        &__page-name {
            font-weight: 600;
            font-size: 26px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 10px;
        }
        &__page-description {
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin-bottom: 25px;
                max-width: 300px;
            }
        }
    }
</style>
