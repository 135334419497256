<template>
    <div class="popup-places">
        <div v-if="!formSended">
            <div class="popup-places__name-container">
                <h1 class="popup-places__page-name">Заполните форму</h1>
                <div class="popup-places__page-description">Организатор проверит наличие свободных мест, и свяжется с вами в ближайшее время</div>
            </div>
            <div class="popup-places__close" @click="close"></div>
            <ValidationObserver ref="validator" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                <BaseTextField
                    v-model="feedback.name"
                    placeholder="Ваше имя"
                    :error-messages="errors[0]"
                />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                <BaseTextField
                    v-model="feedback.phone"
                    placeholder="Телефон"
                    type="tel"
                    autocomplete="off"
                    :error-messages="errors[0]"
                />
                </ValidationProvider>
                <BaseButton
                    class="button-fill popup-places__save"
                    @click="send()">Отправить</BaseButton>
                <div class="popup-places__personal-info">
                    Нажимая кнопку, я соглашаюсь с условиями обработки персональных данных
                </div>
            </ValidationObserver>
        </div>
        <div v-if="formSended" class="popup-places__sended">
            <div class="popup-places__sended__title">Спасибо за вашу заявку!</div>
            <div class="popup-places__close" @click="close"></div>
            <div class="popup-places__sended__text">
                Ваша заявка принята.
                В ближайшее время с вами свяжется организатор путешествия.
                Он сможет предоставить детальную информацию по туру и ответить на любые вопросы.
                Команда GetAdventutres.
            </div>
            <BaseButton
                class="button-fill"
                style="width:300px"
                @click="close"
            >Хорошо</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from 'moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from './BaseButton.vue'
    import BaseTextField from './BaseTextField.vue'
    import BaseTextArea from './BaseTextArea.vue'

    export default {
        name: 'PopupPlaces',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                comment: '',
                link: '',
            },
            formSended: false,
        }),
        props: {
            text: '',
            activeArrival: {},
        },
        computed: {
            ...mapState('trips', {
                trip: state => state.entity
            }),
        },
        async mounted() {
        },
        methods: {
            async send() {
                this.feedback.link = document.location.href;
                const valid = await this.$refs.validator.validate()
                if(valid) {
                    this.feedback.comment = 'Узнать о наличии мест'
                    this.$emit('save', this.feedback);
                    await this.formReverse()
                    await this.clear()
                }
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            async clear () {
                this.feedback = {
                    name: '',
                    phone: '',
                    link: '',
                }
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss">
    .popup-places {
        position: relative;
        max-width: 500px;
        padding: 45px 30px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        border-radius: 10px;
        &__name-container {
            max-width: 350px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
        }
        &__page-name {
            font-weight: 700;
            font-size: 22px;
            line-height: 150%;
            color: #273155;
            margin: 0 auto 12px;
        }
        &__page-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            text-align: center;
            color: #3A4256;
            margin-bottom: 18px;
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #707378;;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 18px;
            height: 18px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin-bottom: 25px;
                max-width: 300px;
            }
        }
    }
</style>
