<template>
    <div class="full-width">
        <div v-if="isDesktop">
            <div class="detailHost__header">
                <div class="detailHost__header__title">
                    <div class="detailHost__header__title__logo" :style="`background-image: url(${ imageSrc(trip.logo) })`"></div>
                    <!-- <div class="detailHost__header__title__powered"></div> -->
                </div>
                <!-- <div class="detailHost__header__main-logo" @click="home"></div> -->
                <div class="detailHost__header__center-container">
                    <div class="detailHost__header__center-container__item" @click="$vuetify.goTo($refs.main-picture, options)">О Путешествии</div>
                    <div class="detailHost__header__center-container__item" @click="$vuetify.goTo($refs.benefits, options)">Наши преимущества</div>
                    <div class="detailHost__header__center-container__item" @click="$vuetify.goTo($refs.map, options)">Маршут</div>
                    <div class="detailHost__header__center-container__item" @click="$vuetify.goTo($refs.arrivals, options)">Даты и цены</div>
                </div>
                <div class="detailHost__header__rigth-container">
                    <!-- <div class="detailHost__header__rigth-container__phone">{{ trip.phone }}</div> -->
                    <BaseButton class="detailHost__header__rigth-container__button" @click="openForm('Хочу с вами!')">Хочу с вами</BaseButton>
                </div>
            </div>
        </div>
        <div v-else class="detailHost__header__mobile">
            <h1 class="detailHost__header__mobile__title" @click="home()"></h1>
                <div class="detailHost__header__mobile__button">
                    <v-menu 
                        bottom 
                        offset-y
                    >
                        <template #activator="{ on, attrs }">
                            <img 
                                src="../../assets/Category.svg"
                                class="detailHost__header__mobile__button__icon"
                                v-bind="attrs" 
                                v-on="on" />
                        </template>
                        <v-list class="detailHost__header__mobile__menu">
                            <v-list-item>
                                <v-list-item-title @click="$vuetify.goTo($refs.main-picture, options)">О Путешествии</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="$vuetify.goTo($refs.benefits, options)">Наши преимущества</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="$vuetify.goTo($refs.map, options)">Маршут</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="$vuetify.goTo($refs.arrivals, options)">Даты и цены</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>{{ trip.phone }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <BaseButton class="detailHost__header__mobile__menu__button">Хочу с вами</BaseButton>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
        </div>
        <div 
            v-if="trip.pictures"
            ref="main-picture"
            class="detailHost__main-picture" 
            :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })`"
        >
            <div class="detailHost__main-picture__opacity"></div>
            <div class="detailHost__main-picture__container">
                <div class="detailHost__block-label">Авторский тур</div>
                <div class="detailHost__main-picture__title"> {{ trip.name }}</div>
                <div class="detailHost__main-picture__description">{{ trip.description }}</div>
                <div class="detailHost__main-picture__buttons">
                    <ValidationObserver ref="validator_1" slim class="detailHost__main-picture__text-field">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                placeholder="Телефон" 
                                :error-messages="errors[0]"
                                v-model="feedback.phone"
                                type="tel"
                                autocomplete="off"
                                />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton class="button-fill detailHost__main-picture__button" style="height: 60px;" @click="downloadForm('validator_1')">Получить программу тура</BaseButton>
                </div>
                <div class="detailHost__main-picture__personal-info">
                    Нажимая кнопку, я соглашаюсь с условиями обработки персональных данных
                </div>
                <!-- <div v-if="headerformsended" class="form__sended__text" style="max-width:500px; margin-top: 40px">
                    <div style="margin-bottom: 20px">
                        <span v-if="secondsLeft > 0">Через {{ secondsLeft }} секунд вы будете перенаправлены на страницу где сможете посмотреть и скачать подробную презентацию по туру.</span>
                        Мы также свяжем вас с организатором путешествия. Он сможет предоставить детальную информацию по туру и ответить на любые вопросы.
                        Если страница с презентацией не отрылась, нажмите кнопку ниже.
                        Команда GetAdventutres.
                    </div>
                    <BaseButton v-if="headerformsended && secondsLeft === 0" @click="secondDownload">Программа тура</BaseButton>
                </div> -->
                <v-dialog 
                    v-model="headerformsended"
                    max-width="600px"
                >
                    <div class="detailHost__main-picture__form" style="max-width:500px; margin-top: 40px">
                        <div class="form__close" @click="closeHeaderFormSended"></div>
                        <div style="margin-bottom: 20px">
                            <span v-if="secondsLeft > 0">Через {{ secondsLeft }} секунд вы будете перенаправлены на страницу где сможете посмотреть и скачать подробную презентацию по туру.</span>
                            Мы также свяжем вас с организатором путешествия. Он сможет предоставить детальную информацию по туру и ответить на любые вопросы.
                            Если страница с презентацией не отрылась, нажмите кнопку ниже.
                            Команда GetAdventutres.
                        </div>
                        <BaseButton v-if="secondsLeft === 0" @click="secondDownload">Программа тура</BaseButton>
                    </div>
                </v-dialog>
                <div class="detailHost__main-picture__scroll">
                    <img class="detailHost__main-picture__scroll__icon" src="../../assets/mouse.svg" />
                <div class="detailHost__main-picture__scroll__text">Скролльте вниз</div>
            </div>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.numbers === true" class="detailHost__main-info">
            <div class="detailHost__block-container detailHost__main-info__container">
                <div class="detailHost__main-info__left-block">
                    <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 130px;">Что мы увидим</div>
                    <div class="detailHost__block-title">Наше путешествие в цифрах</div>
                    <div class="detailHost__block-description" v-if="trip.numbersDescription">{{ trip.numbersDescription }}</div>
                    <div class="detailHost__main-info__numbers">
                        <div class="detailHost__main-info__numbers__item">
                            <div class="detailHost__main-info__numbers__picture days"><img src="../../assets/days.svg" /></div>
                            <div class="detailHost__main-info__numbers__text-container">
                                <div class="detailHost__main-info__numbers__value">{{ trip.days }} дней</div>
                                <div class="detailHost__main-info__numbers__description">Общая длительность путешествия</div>
                            </div>
                        </div>
                        <div class="detailHost__main-info__numbers__item">
                            <div class="detailHost__main-info__numbers__picture days"><img src="../../assets/people.svg" /></div>
                            <div class="detailHost__main-info__numbers__text-container">
                                <div class="detailHost__main-info__numbers__value">{{ trip.groupSize }} человек</div>
                                <div class="detailHost__main-info__numbers__description">Активно участвуют в группе</div>
                            </div>
                        </div>
                        <div class="detailHost__main-info__numbers__item">
                            <div class="detailHost__main-info__numbers__picture days"><img src="../../assets/bus.svg" /></div>
                            <div class="detailHost__main-info__numbers__text-container">
                                <div class="detailHost__main-info__numbers__value">{{ trip.sities }} городов</div>
                                <div class="detailHost__main-info__numbers__description">Включено в программу тура</div>
                            </div>
                        </div>
                        <div class="detailHost__main-info__numbers__item">
                            <div class="detailHost__main-info__numbers__picture days"><img src="../../assets/eiffel-tower.svg" /></div>
                            <div class="detailHost__main-info__numbers__text-container">
                                <div class="detailHost__main-info__numbers__value">{{ trip.places }} мест</div>
                                <div class="detailHost__main-info__numbers__description">Посетим во время поездки</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detailHost__main-info__right-block">
                    <div v-if="!consultationformSended">
                        <div class="detailHost__main-info__right-block__title">Нужна консультация по туру?</div>
                        <div class="detailHost__main-info__right-block__text">Наш эксперт свяжется с вами в ближайшее время и ответит на все интересующие вопросы</div>
                        <ValidationObserver ref="validator_2" slim>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField v-model="feedback.name" placeholder="Ваше имя" :error-messages="errors[0]"/>
                                <BaseTextField 
                                    v-model="feedback.phone" 
                                    placeholder="Телефон"
                                    type="tel"
                                    autocomplete="off"
                                    :error-messages="errors[0]"/>
                            </ValidationProvider>
                        </ValidationObserver>
                        <BaseButton @click="saveForm('validator_2')" class="button-fill" style="width: 100%; height: 56px">Отправить</BaseButton>
                        <div class="detailHost__main-info__right-block__personal-info">
                            Нажимая кнопку, я соглашаюсь с условиями обработки персональных данных
                        </div>
                    </div>
                    <div v-if="consultationformSended" class="detailHost__main-info__right-block__title">
                        Запрос на консультацию успешно отправлен!
                        <div class="detailHost__main-info__right-block__text">
                            В ближайшее время организатор свяжется с вами, предоставит подробную информацию по туру и ответит на все ваши вопросы. 
                            Это бесплатно и ни к чему не обязывает</div>
                        <!-- <BaseButton
                            class="button-fill"
                            style="width:100%"
                            @click="formReverse"
                        > Задать ещё вопрос </BaseButton> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-if="trip.programs && trip.visibleBlocks && trip.visibleBlocks.programs === true" class="detailHost__privilege">
            <div class="detailHost__block-container detailHost__privilege__container">
                <div class="detailHost__privilege__left-block">
                    <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 145px;">Что мы испытаем</div>
                    <div class="detailHost__block-title">Что будет в нашем путешествии?</div>
                    <div class="detailHost__block-description">{{ trip.momentsDescription }}</div>
                    <BaseButton v-if="isDesktop" class="button-fill" @click="openDownloadForm">Получить программу тура</BaseButton>
                </div>
                <div class="detailHost__privilege__right-block">
                    <template v-for="(moment, index) in trip.moments">
                        <div 
                            :key="'moment_' + index"
                            class="detailHost__privilege__right-block__item">
                            <div class="detailHost__privilege__right-block__item__title">{{ moment.title }}</div>
                            <div class="detailHost__privilege__right-block__item__text">{{ moment.description }}</div>
                        </div>
                    </template>
                </div>
                <BaseButton v-if="!isDesktop" class="button-fill" style="width: 100%; height: 52px" @click="openDownloadForm">Получить программу тура</BaseButton>
            </div>
        </div>
        <div 
            v-if="trip.programs && trip.visibleBlocks && trip.visibleBlocks.programs === true"
            class="detailHost__program"
        >
            <div class="detailHost__block-container detailHost__program__container">
                <div class="detailHost__block-label" style="color: #00A699; border-color: #E2F5F4; max-width: 165px;">Что мы будем делать</div>
                <div class="detailHost__block-title" style="margin-bottom: 40px">Расписание по дням</div>
                <template v-for="(program, index) in filteredProgram">
                    <div
                        :key="'program_' + index"
                        class="detailHost__program__item"
                    >
                        <div class="detailHost__program__item__picture" :style="`background-image: url(${ imageSrc(program.pictures[0]) })`"></div>
                        <div class="detailHost__program__item__text-container__label on-picture">{{ index + 1 }} день</div>
                        <div class="detailHost__program__item__text-container">
                            <div class="detailHost__program__item__text-container__label">{{ index + 1 }} день</div>
                            <div class="detailHost__program__item__text-container__title">{{ program.title }}</div>
                            <div class="detailHost__program__item__text-container__description">{{ program.description }}</div>
                        </div>
                    </div>
                </template>
                <div class="detailHost__program__buttons">
                    <BaseButton
                        v-if="programsLenght===3"
                        class="detailHost__program__button margin"
                        @click="showAllDays">Смотреть все дни</BaseButton>
                    <BaseButton
                        v-if="programsLenght===100"
                        class="detailHost__program__button margin"
                        @click="hideAllDays">Скрыть программу</BaseButton>
                    <BaseButton class="button-fill detailHost__program__button" @click="openDownloadForm">Получить программу тура</BaseButton>
                </div>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.map === true" ref="map" class="detailHost__map">
            <div class="detailHost__block-container detailHost__map__container">
                <div class="detailHost__map__text-container">
                    <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 120px;">Наш маршрут</div>
                    <div class="detailHost__block-title">Маршрут путешествия</div>
                    <div class="detailHost__block-description">В процессе путешествия мы посетим {{ trip.direction.length }} мест: 
                        <template v-if="trip.direction && trip.direction[0] && trip.direction[0].city">{{ trip.direction[0].city.title }}, </template>
                        <template v-if="trip.direction && trip.direction[1] && trip.direction[1].city">{{ trip.direction[1].city.title }}, </template>
                        <template v-if="trip.direction && trip.direction[2] && trip.direction[2].city">{{ trip.direction[2].city.title }}, </template>
                        <template v-if="trip.direction && trip.direction[3] && trip.direction[3].city">{{ trip.direction[3].city.title }}, </template>
                        <template v-if="trip.direction && trip.direction[4] && trip.direction[4].city">{{ trip.direction[4].city.title }}</template>
                        <template v-if="trip.direction.length > 5" >, и ещё {{ trip.direction.length - 5 }}</template>
                    </div>
                    <BaseButton v-if="isDesktop" class="button-fill" @click="openForm('Получить подробный маршрут')">Получить подробный маршрут</BaseButton>
                </div>
                <YandexMap
                    class="detailHost__map__container__map"
                    :directions="trip.direction"
                />
                <BaseButton v-if="!isDesktop" class="button-fill" style="margin-top: 27px; width: 100%" @click="openForm('Получить подробный маршрут')">Получить подробный маршрут</BaseButton>
            </div>
        </div>
        <div v-if="trip.benefits && trip.visibleBlocks && trip.visibleBlocks.benefits === true" ref="benefits" class="detailHost__benefits">
            <div class="detailHost__block-container detailHost__benefits__container"> 
                <div class="detailHost__benefits__top-container">
                    <div class="detailHost__benefits__text-container">
                        <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 105px;">Почему мы</div>
                        <div class="detailHost__block-title">Наши преимущества</div>
                        <div class="detailHost__block-description">{{ trip.benefitsDescription }}</div>
                    </div>
                    <div class="detailHost__benefits__picture-container" :style="`background-image: url(${ imageSrc(trip.benefitsPicture) })`"></div>
                </div>
                <div class="detailHost__benefits__items-container" v-if="trip.benefits[0].type">
                    <template v-for="(benefit, index) in trip.benefits">
                        <div :key="'benefit_' + index" class="detailHost__benefits__items-container__item">
                            <div class="detailHost__benefits__items-container__item__icon">
                                <!-- <img src="../../assets/benefit-icon.svg" /> -->
                                <template v-if="formattedBenefitsTypes(index) && formattedBenefitsTypes(index).length">
                                    <img :src="`${ imageSrc(formattedBenefitsTypes(index)[0].picture) }`" class="detailHost__benefits__items-container__item__icon__picture" />
                                </template>
                            </div>
                            <div class="detailHost__benefits__items-container__item__text-container">
                                <div class="detailHost__benefits__items-container__item__title"> {{ benefit.title }}</div>
                                <div class="detailHost__benefits__items-container__item__description"> {{ benefit.description }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.accommodations === true && trip.accommodations[0].type" class="detailHost__accomodations">
            <div class="detailHost__block-container detailHost__accomodations__container">
                <div class="detailHost__accomodations__text-container">
                    <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 145px;">Организация тура</div>
                    <div class="detailHost__block-title">Где вы будете жить</div>
                    <div class="detailHost__block-description">{{ trip.accommodationsDescription }}</div>
                    <!-- <div v-if="!isDesktop" class="detailHost__accomodations__type"> {{ formattedAccommodationTypes[0].title }}</div> -->
                    <div class="detailHost__accomodations__items-container">
                        <!-- <div v-if="isDesktop" class="detailHost__accomodations__type"> {{ formattedAccommodationTypes[0].title }}</div> -->
                        <template v-for="(accommodation, index) in trip.accommodations">
                            <div
                                :key="'accommodation_' + index"
                                class="detailHost__accomodations__item"
                                :class="activeAccommodationIndex === index? 'detailHost__accomodations__item__active' : ''"
                                @click="activeAccommodation(index)"
                                v-if="nameOfAccommodationTypes(index) && nameOfAccommodationTypes(index)[0]"
                            >
                            {{ nameOfAccommodationTypes(index)[0].title }}
                            </div>
                        </template>
                    </div>
                    <div class="detailHost__accomodations__detail">
                        <div class="detailHost__accomodations__detail__title-container">
                            <div class="detailHost__accomodations__detail__icon">
                                <template v-if="formattedAccommodationTypes && formattedAccommodationTypes.length">
                                    <img :src="`${ imageSrc(formattedAccommodationTypes[0].picture) }`" class="detailHost__accomodations__detail__icon" />
                                </template>
                            </div>
                            <div class="detailHost__accomodations__detail__title-container__text">
                                <div v-if="trip.accommodations[activeAccommodationIndex]" class="detailHost__accomodations__detail__title">{{ trip.accommodations[activeAccommodationIndex].title }}</div>
                                <div v-if="trip.accommodations[activeAccommodationIndex]" class="detailHost__accomodations__detail__raiting"> {{ trip.accommodations[activeAccommodationIndex].bookingLink }}
                                    <!-- <img src="../../assets/StarAlone.svg" class="detailHost__accomodations__detail__raiting__star"  />
                                    {{ trip.accommodations[activeAccommodationIndex].raiting }} -->
                                </div>
                            </div>
                        </div>
                        <div class="detailHost__block-description">{{ trip.accommodations[activeAccommodationIndex].description }}</div>
                    </div>
                </div>
                <ThumbnailsSlider
                    style="margin: auto 0"
                    :items="trip.accommodations[activeAccommodationIndex].pictures"
                    :activeItem="0"
                />
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.activities === true" class="detailHost__activities">
            <div class="detailHost__block-container detailHost__activities__container">
                <div class="detailHost__block-container__gallery">
                    <ThumbnailsSlider
                        style="margin: auto 0"
                        :items="trip.activities"
                    />
                </div>
                <div class="detailHost__activities__text-container">
                    <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 150px;">Активности в туре</div>
                    <div class="detailHost__block-title">Что вы испытаете</div>
                    <div class="detailHost__block-description">{{ trip.activitiesDescription }}</div>
                    <BaseButton v-if="isDesktop" class="button-fill detailHost__activities__button" @click="openForm('Узнать больше', 'Узнать об активностях')">Узнать больше</BaseButton>
                </div>
            </div>
            <BaseButton v-if="!isDesktop" class="button-fill detailHost__activities__button" @click="openForm('Узнать больше')">Узнать больше</BaseButton>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.transports === true" class="detailHost__transports">
            <div class="detailHost__block-container detailHost__transports__container"> 
                <div class="detailHost__transports__text-container">
                    <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 95px;">Транспорт</div>
                    <div class="detailHost__block-title">На чем мы будем перемещаться</div>
                    <div class="detailHost__block-description">{{ trip.transportsDescription }}</div>
                    <BaseButton v-if="isDesktop" class="button-fill" style="width:270px; align-self:flex-start;" @click="openForm('Узнать больше', 'Узнать о транспорте')">Узнать больше</BaseButton>
                </div>
                <ThumbnailsSlider
                    style="margin: auto 0"
                    :items="trip.transports"
                />
            </div>
            <BaseButton v-if="!isDesktop" class="button-fill detailHost__activities__button" @click="openForm('Узнать больше', 'Узнать о транспорте')">Узнать больше</BaseButton>
        </div>
        <div class="detailHost__organizer">
            <div class="detailHost__block-container detailHost__organizer__container">
                <div class="detailHost__organizer__text-container">
                    <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 145px;">Организатор тура</div>
                    <div class="detailHost__block-title">Вас будет сопровождать</div>
                    <div class="detailHost__block-description">Каждая новая экскурсия и комплексный тур обязательно проходят проверку</div>
                </div>
                <Organizer v-if="isDesktop" class="detailHost" :user="trip.user" @openPopup="openForm"/>
                <Organizer v-if="!isDesktop" :user="trip.user" @openPopup="openForm"/>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.included === true" class="detailHost__included">
            <div class="detailHost__block-container detailHost__included__container">
                <div class="detailHost__included__text-container">
                    <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 175px;">Что входит в цену тура</div>
                    <div class="detailHost__block-title">Что включено</div>
                </div>
                <div class="detailHost__included__items-container">
                    <TripAdvantages
                        v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== ''"
                        class="detailHost__included__item"
                        :items="trip.included" 
                        :openAllItems="true"
                        />
                    <TripAdvantages
                        v-if="trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''"
                        class="detailHost__included__item notIcluded"
                        :items="trip.notIncluded" />
                </div>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.arrivals === true" ref="arrivals" class="detailHost__arrivals">
            <div class="detailHost__block-container detailHost__arrivals__container">
                <!-- <img src="../../assets/Landing-offer.jpg" /> -->
                <div class="detailHost__arrivals__left-block">
                    <div class="detailHost__block-title" style="color: #fff;">Узнайте о наличии мест в группе на подходящую вам дату</div>
                    <div class="detailHost__block-description detailHost__arrivals__block-description">Это бесплатно и вас ни к чему не обязывает</div>
                    <BaseButton
                        @click="openPlacesPopup"
                        class="button-white"
                        style="width: 290px;">Узнать о наличии мест</BaseButton>
                    <!-- <div v-if="filteredArrivals[0]" class="detailHost__arrivals__left-block__text">
                        При брони до {{ filteredArrivals[0].discountBefore | moment('DD MMM YYYY') }} гарантированна скидка 
                        <span v-html="priceFormated(priceWithSale, filteredArrivals[0].currency)"></span>
                    </div> -->
                    <div class="detailHost__arrivals__left-block__text" style="font-size: 14px;">
                        {{ activeArrival.gift }}
                    </div>
                    <div class="detailHost__arrivals__left-block__text">
                        для бронирования тура достаточно внести предоплату 15% *
                    </div>
                </div>
                <div class="detailHost__arrivals__right-block">
                    <div class="detailHost__arrivals__right-block__title">Стоимость и даты тура</div>
                    <DateSelect
                        class="no-text"
                        style="width: 230px;"
                        :items="dateSelectItems"
                        item-text="label"
                        item-value="value"
                        :value="0"
                        @change="setActiveArrival"
                    />
                    <div 
                        v-if="filteredArrivals[0]"
                        class="detailHost__arrivals__right-block__price">
                        <span v-html="priceFormated(priceWithSale, filteredArrivals[0].currency)"></span>
                        <span class="detailHost__arrivals__right-block__price-full" v-if="activeArrival && activeArrival.discount" v-html="priceFormated(activeArrival.price, activeArrival.currency)"></span>
                        <span class="detailHost__arrivals__right-block__price__sale" v-if="activeArrival && activeArrival.discount" v-html="'-' + activeArrival.discount + '%'"></span>
                    </div>
                    <div class="detailHost__arrivals__right-block__text">Открыто раннее бронирование. Возможны индивидуальные рассрочки!</div>
                    <v-dialog 
                        v-model="shownPlacesPopup"
                    >
                        <PopupPlaces
                            @close="closePlacesPopup"
                            :activeArrival="activeArrival"
                        />
                    </v-dialog>
                </div>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.community === true" class="detailHost__reviews">
            <div class="detailHost__block-container detailHost__reviews__container">
                <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 105px;">Наши люди</div>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="detailHost__block-title">Сообщество путешественников</div>
                    <template v-if="isDesktop && pageCount > 1">
                            <v-pagination 
                                class="only-arrow"
                                v-model="reviewsPage"
                                :length="pageCount"
                                circle
                                @input="getReviews"
                            ></v-pagination>
                    </template>
                </div>
                <div class="detailHost__block-description">Инвестиции в эмоции, людей и уникальный опыт - это лучшие инвестиции в себя</div>
                <div class="detailHost__reviews__items-container">
                    <template v-for="(traveler, index) in trip.community.slice(firstReview, (firstReview + reviewsCounter))">
                        <Review
                            :key="'traveler_' + index"
                            :review="traveler"
                            :isDesktop="isDesktop"
                        />
                    </template>
                </div>
                <template v-if="isDesktop && pageCount > 1">
                    <div class="text-center">
                        <v-pagination
                            class="only-circle"
                            v-model="reviewsPage"
                            :length="pageCount"
                            circle
                            @input="getReviews"
                        ></v-pagination>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.photos === true" class="detailHost__photos">
            <div class="detailHost__block-container detailHost__photos__container">
                <div class="detailHost__block-label" style="color: #DA0A63; border-color: #FFE4F0; max-width: 140px;">Лучшие моменты</div>
                <div class="detailHost__block-title">Фотоотчеты с наших путешествий</div>
                <div class="detailHost__block-description">Взгляните на мир нашими глазами. Посмотрите фотографии о том, как проходят наши путешествия</div>
                <Gallery :trips="trips" />
            </div>
            
        </div>
        <div v-if="trip.visibleBlocks && trip.visibleBlocks.questions === true" class="detailHost__questions">
            <div class="detailHost__block-container detailHost__questions__container">
                <div class="detailHost__block-label" style="color: #2F80ED; border-color: #EFF2FF; max-width: 205px;">Часто задаваемые вопросы</div>
                <div class="detailHost__block-title">Вопросы и ответы</div>
                    <TripQuestions
                        v-if="trip.questions && trip.questions.length > 0 && trip.questions[0].question !== ''"
                        :items="trip.questions"
                        class="detailHost__questions__items"
                    />
            </div>
        </div>
        <div class="detailHost__block-container detailHost__download-form__container">
            <div class="detailHost__download-form__form-container">
                <div v-if="!programformSended" class="detailHost__download-form__text">
                    <div class="detailHost__download-form__title">Получите программу тура</div>
                    <div class="detailHost__download-form__description">Не забудьте скачать программу  тура с подробным описанием маршрута в формате PDF</div>
                </div>
                <div v-if="!programformSended" class="detailHost__download-form__form">
                    <ValidationObserver ref="validator_3" slim>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField placeholder="Ваше имя" v-model="feedback.name" :error-messages="errors[0]"/>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField 
                                    placeholder="Телефон" 
                                    v-model="feedback.phone"
                                    type="tel"
                                    autocomplete="off"
                                    :error-messages="errors[0]"/>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField placeholder="Ваша почта" v-model="feedback.email" :error-messages="errors[0]"/>
                            </ValidationProvider>
                    </ValidationObserver>
                    <div class="detailHost__download-form__button-container">
                        <BaseButton @click="downloadForm('validator_3')" class="button-fill detailHost__download-form__button">Получить программу тура</BaseButton>
                        <div class="detailHost__download-form__personal-info">Нажимая кнопку, я соглашаюсь с условиями обработки персональных данных</div>
                    </div>
                </div>
                <div v-if="programformSended" class="detailHost__main-info__right-block__title" style="max-width: 500px;">
                    Запрос на консультацию успешно отправлен!
                    <div class="detailHost__main-info__right-block__text">
                        <span v-if="secondsLeft > 0">Через {{ secondsLeft }} секунд вы будете перенаправлены на страницу где сможете посмотреть и скачать подробную презентацию по туру.</span> 
                        Мы также свяжем вас с организатором путешествия. Он сможет предоставить детальную информацию по туру и ответить на любые вопросы. 
                        Если вы не были переадресованы на страницу презентации, пожалуйста, <a @click="secondDownload">нажмите здесь</a>. Команда GetAdventutres</div>
                    <!-- <BaseButton
                        class="button-fill"
                        style="width:100%"
                        @click="formReverse"
                    > Задать ещё вопрос </BaseButton> -->
                </div>
            </div>
            <div v-if="isDesktop" style="display: flex; align-items: flex-end;"> <img src="../../assets/Magasine.png" /></div>
        </div>
        <v-dialog 
            v-model="shownForm"
            max-width="600px"
        >
            <PopupForm
                @close="closeForm"
                :text="text"
                :comment="this.feedback.comment"
                @save="saveFeedback"
                width="600px"
            />
        </v-dialog>
        <v-dialog 
            v-model="shownDownloadForm"
            max-width="600px"
        >
            <DownloadForm
                @close="closeDownloadForm"
                @secondDownload="secondDownload"
                @save="saveFeedback"
            />
        </v-dialog>
        <a
            ref="dowloadProgram"
            :href="download(trip.PDF)"
            target="_blank"></a>
        <div class="detailHost__footer">
            <div class="detailHost__footer__links">
                <div class="detailHost__footer__links__left-container">
                    <div>
                        <div class="detailHost__footer__links__contacts__key">Остались вопросы? Напишите нам</div>
                        <!-- <div class="detailHost__footer__links__contacts__value"><a :href="`mailto:${ trip.mail }`">{{ trip.mail }}</a></div> -->
                        <div class="detailHost__footer__links__contacts__social">
                            <!-- <a
                                v-if="pageTemplate.contacts.fb"
                                :href="pageTemplate.contacts.fb"
                                target="_blank">
                                <div class="detailHost__footer__links__contacts__social__item facebook"></div>
                            </a> -->
                            <a
                                v-if="trip.VK && trip.VK !== ''"
                                :href="`https://vk.com/${ trip.VK }`"
                                target="_blank">
                                <div class="detailHost__footer__links__contacts__social__item VK"></div>
                            </a>
                            <a
                                v-if="trip.telegram && trip.telegram !== ''"
                                :href="`https://t.me/${ trip.telegram }`"
                                target="_blank">
                                <div class="detailHost__footer__links__contacts__social__item telegram"></div>
                            </a>
                            <a
                                v-if="trip.Inst && trip.Inst !== ''"
                                :href="`https://www.instagram.com/${ trip.Inst }`"
                                target="_blank">
                                <div class="detailHost__footer__links__contacts__social__item instagram"></div>
                            </a>
                        </div>
                    </div>
                    <!-- <div>
                        <div class="detailHost__footer__links__contacts__key">Или задайте их по телефону</div>
                        <div class="detailHost__footer__links__contacts__value">{{ trip.phone }}</div>
                    </div> -->
                </div>
                <div class="detailHost__footer__links__right-container">
                    <div class="detailHost__footer__links__right-container__item" @click="$vuetify.goTo($refs.main-picture, options)">О Путешествии</div>
                    <div class="detailHost__footer__links__right-container__item" @click="$vuetify.goTo($refs.map, options)">Маршут</div>
                    <div class="detailHost__footer__links__right-container__item" @click="$vuetify.goTo($refs.arrivals, options)">Даты и цены</div>
                    <div class="detailHost__footer__links__right-container__item" @click="$vuetify.goTo($refs.benefits, options)">Наши преимущества</div>
                </div>
            </div>
            <div class="detailHost__footer__static">
                <div class="detailHost__footer__static__item">© GetAdventures, 2022</div>
                <div class="detailHost__footer__static__item">Правила использования сайта</div>
                <div class="detailHost__footer__static__item">Политика конфиденциальности</div>
                <!-- <div class="detailHost__footer__static__affetta">
                    <div class="detailHost__footer__static__item">Дизайн от AFFETTA</div>
                    <img src="../../assets/AFFETTA.svg" style="width: 25px; heigh: 25px;" />
                </div> -->
            </div>
            <template v-for="(script, script_i) in trip.scripts">
                <div :key="script_i" v-html="script"></div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';
    import moment from 'moment';
    import { priceFormated } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseButton from '../../components/common/BaseButton.vue'
    import YandexMap from '../../components/common/trip/YandexMap.vue'
    import Organizer from '../../components/common/trip/Organizer.vue'
    import TripAdvantages from '../../components/common/trip/Advantages.vue'
    import Review from '../../components/common/ReviewBig.vue'
    import Gallery from '../../components/common/Gallery.vue'
    import TripQuestions from '../../components/common/trip/Questions.vue'
    import ThumbnailsSlider from '../../components/common/ThumbnailsSlider.vue'
    import PopupForm from '../../components/common/PopupForm.vue'
    import DownloadForm from '../../components/common/DownloadForm.vue'
    import PopupPlaces from '../../components/common/PopupPlaces.vue'
    import DateSelect from '../../components/common/trip/DateSelect.vue'

    export default {
        name: 'TripDetail',
        metaInfo () {
            return {
                title: this.trip.name,
                meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: this.trip.activitiesDescription,
                }],
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            YandexMap,
            Organizer,
            TripAdvantages,
            Review,
            Gallery,
            TripQuestions,
            ThumbnailsSlider,
            PopupForm,
            DownloadForm,
            PopupPlaces,
            DateSelect,
        },
        data: () => ({
            isDesktop: false,
            imageSrc,
            SplideSlideCounter,
            programsLenght: 3,
            priceFormated,
            activeAccommodationIndex: 0,
            shownForm: false,
            shownDownloadForm: false,
            shownPlacesPopup: false,
            consultationformSended: false,
            programformSended: false,
            headerformsended: false,
            text: '',
            secondsLeft: 0,
            intervalId: undefined,
            // reviewCounter: 4,
            feedback: {
                trip: null,
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
                personalDataAgree: true,
            },
            formSended: false,
            activeArrivalIndex: 0,
            reviewsPage: 1,
            firstReview: 0,
            reviewsCounter: 3,
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trip: state => state.entity,
                trips: state => state.entities,
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.footer
            }),
            ...mapState('dictionaries', ['accommodationsTypes', 'benefitsTypes']),
            formattedAccommodationTypes() {
                return this.accommodationsTypes ? this.accommodationsTypes.filter(item => (this.trip.accommodations[this.activeAccommodationIndex].type.indexOf(item.code) >= 0)) : [];
            },
            filteredProgram() {
                return this.trip.programs.slice(0, this.programsLenght)
            },
            filteredArrivals() {
                const now = new Date();
                let arrivals = this.trip.arrivals || null;
                if(arrivals) {
                    arrivals = arrivals.filter(arrival => {
                        return new Date(arrival.start).getTime() > now.getTime();
                    });
                    arrivals = arrivals.sort((arrivalA, arrivalB) => {
                        return new Date(arrivalA.start).getTime() - new Date(arrivalB.start).getTime();
                    });
                    if(this.selectedMonth) {
                        arrivals = arrivals.filter(arrival => {
                            return moment(arrival.start).format('YYYY-MM-01') === this.selectedMonth;
                        });
                    } else {
                        arrivals = arrivals.slice(0, 4);
                    }
                }
                return arrivals || [];
            },
            dateSelectItems() {
                return this.filteredArrivals ? this.filteredArrivals.map((item, index) => ({
                    label: moment(item.start).format('DD.MM.YY') + ' - ' + moment(item.finish).format('DD.MM.YY'),
                    value: index
                })) : [];
            },
            activeArrival() {
                return this.filteredArrivals[this.activeArrivalIndex] || {};
            },
            priceWithSale() {
                if (this.activeArrival.price && this.activeArrival.discount){
                    return Math.round(this.activeArrival.price - (this.activeArrival.price * (this.activeArrival.discount / 100)))
                }
                return this.activeArrival ? + this.filteredArrivals[0].price : 0
            },
            pageCount() {
                return Math.ceil(this.trip.community.length / this.reviewsCounter)
            },
        },
        async mounted() {
            if (this.$route.params.code) {
                await store.dispatch('trips/getByCode', { code: this.$route.params.code });
                await store.dispatch('trips/photos', { filter: { organizer: this.trip.user._id } });
                // await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id } });
            } else {
                const { host } = window.location;
                if (host) {
                    await store.dispatch('trips/getByHost', { host });
                    await store.dispatch('trips/photos', { filter: { organizer: this.trip.user._id } });
                    // await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id } });
                }
            }
            // if(this.trip._id && this.trip.scripts && this.trip.scripts.length) {
            //     for(const script of this.trip.scripts) {
            //         document.head.appendChild(script);
            //     }
            // }
            this.feedback.trip = this.trip._id;
            // await this.getTrips();
            this.reviewsCounter = SplideSlideCounter(305, 1280);
            // await this.getReviews();
            this.onResize();
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            // async getReviews() {
            //     if(this.isDesktop){
            //         await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id }, limit: this.reviewsCounter, page: this.reviewsPage });
            //     } else {
            //         await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id }, limit: 20, page: 1 });
            //     }
            // },
            getReviews() {
                this.firstReview =  this.reviewsPage * this.reviewsCounter - this.reviewsCounter
            },
            async home() {
                await this.$router.push({ name: 'home' });
            },
            startTimer(callback) {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 10;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                        callback()
                    }
                }, 1000);
            },
            nameOfAccommodationTypes(index) {
                return this.accommodationsTypes ? this.accommodationsTypes.filter(item => (this.trip.accommodations[index].type.indexOf(item.code) >= 0)) : [];
            },
            setActiveArrival(index) {
                this.activeArrivalIndex = index;
            },
            formattedBenefitsTypes(index) {
                return this.benefitsTypes ? this.benefitsTypes.filter(item => (this.trip.benefits[index].type.indexOf(item.code) >= 0)) : [];
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            showAllDays() {
                this.programsLenght = 100
            },
            hideAllDays() {
                this.programsLenght = 3
            },
            activeAccommodation(i) {
                this.activeAccommodationIndex = i;
            },
            download(file) {
                return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : ''
            },
            openForm(text, comment) {
                this.shownForm = true
                this.text = text
                if(comment && comment !== '') {
                    this.feedback.comment = comment
                } else {
                    this.feedback.comment = text
                }
                
            },
            closeForm() {
                this.shownForm = false
            },
            openDownloadForm() {
                this.shownDownloadForm = true
            },
            closeDownloadForm() {
                this.shownDownloadForm = false
            },
            openPlacesPopup() {
                this.shownPlacesPopup = true
            },
            closePlacesPopup() {
                this.shownPlacesPopup = false
            },
            closeHeaderFormSended() {
                this.headerformsended = false
            },
            // showMoreReview() {
            //     this.reviewCounter = this.reviewCounter + 3
            // },
            // hideReview() {
            //     this.reviewCounter = 3
            // },
            // async saveForm() {
            //     this.feedback.link = document.location.href;
            //     await store.commit('feedbacks/SET_ENTITY', this.feedback);
            //     await store.dispatch('feedbacks/save');
            //     await store.commit('feedbacks/CLEAR_ENTITY');
            //     // await this.formReverse()
            //     await this.clearForm()
            // },
            async saveForm(validator) {
                this.feedback.link = document.location.href;
                const valid = await this.$refs[validator].validate()
                if(valid) {
                    await this.saveFeedback(this.feedback);
                    await this.clearForm();
                    if(validator === 'validator_2') {
                        this.consultationformSended = true
                    }
                    if(validator === 'validator_3') {
                        this.programformSended = true
                    }
                    if(validator === 'validator_1') {
                        this.headerformsended = true
                    }
                }
            },
            async saveFeedback(feedback) {
                feedback.trip = this.trip._id;
                await store.commit('feedbacks/SET_ENTITY', feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            async downloadProgram() {
                if(this.trip.PDF) {
                    this.startTimer(async () => {
                        await this.$refs.dowloadProgram.click();
                    });
                }
            },
            async secondDownload() {
                if(this.trip.PDF) {
                    await this.$refs.dowloadProgram.click();
                }
            },
            async downloadForm(validator) {
                this.feedback.comment = 'Получить программу тура'
                this.saveForm(validator)
                const valid = await this.$refs[validator].validate()
                if(valid) {
                    this.downloadProgram()
                }
            },
            clearForm() {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                }
            },
        }
    }
</script>

<style lang="scss">
.detailHost {
    font-family: 'Inter';
    font-style: normal;
    &__block-container {
        padding: 80px 0 100px;
        margin: 0 auto;
        max-width: 1280px;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 1440px) {
            width: 100%;
            padding: 80px 20px 100px;
        }
        @media all and (max-width: 768px) {
            width: 100%;
            padding: 46px 20px 40px;
        }
        &__gallery {
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__block-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #FFFFFF;
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        border-radius: 22px;
        max-width: 125px;
        margin: auto 0;
        padding: 8px 16px;
        
    }
    &__block-title {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #3A4256;
        margin-top: 20px;
        @media all and (max-width: 768px) {
            font-size: 24px;
            line-height: 29px;
        }
    }
    &__block-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #3A4256;
        margin: 24px 0px;
        @media all and (max-width: 768px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin: 15px 0px;
        }
    }
    &__main-picture {
        width: 100%;
        min-height: 600px;
        // height: 32vw;
        display: flex;
        background-size: cover;
        background-position: center;
        position: relative;
        @media all and (max-width: 768px) {
            border-radius: 0 0 10px 10px;
            overflow: hidden;
        }
        &__container {
            margin: 5% auto 20px;
            width: 1280px;
            z-index: 1;
            position: relative;
            @media all and (max-width: 1300px) {
                width: 100%;
                padding: 0 20px 20px;
                margin-left: 0;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 60px 20px 20px;
                margin: 0;
                min-width: 100%;
            }
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            background: #000000;
            // background: #543919;
        }
        &__scroll {
            position: absolute;
            display: flex;
            // flex-direction: column;
            height: 150px;
            width: 150px;
            right: -100px;
            bottom: 20%;
            transform: rotate(-90deg);
            @media all and (max-width: 768px) {
                display: none;
            }
            &__icon {
                width: 20px;
                height: 30px;
                transform: rotate(90deg);
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #FFFFFF;
                margin-left: 15px;
                margin-top: 3px;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 46px;
            line-height: 56px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            margin: 28px 0px;
            width: 520px;
            @media all and (max-width: 768px) {
                font-size: 32px;
                line-height: 48px;
                width: 340px;
                margin: 14px 0px 24px;
            }
        }
        &__description {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #FFFFFF;
            width: 520px;
            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
                width: 340px;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            // align-items: flex-start;
            // justify-content: center;
            @media all and (max-width: 768px) {
                flex-direction: column;
                margin-top: 25px;
            }
        }
        &__text-field {
            width: 265px;
            margin-top: 60px;
            @media all and (max-width: 768px) {
                min-width: 320px;
                width: 90vw;
                margin: 0 auto;
            }
        }
        &__button {
            margin-left: 10px;
            width: 265px;
            margin-top: 46px;
            @media all and (max-width: 768px) {
                width: 100%;
                margin-left: 0;
                margin-top: 0;
            }
        }
        &__personal-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #FFFFFF;
            width: 400px;
            @media all and (max-width: 768px) {
                width: 330px;
                margin-top: 12px;
            }
        }
        &__form {
            position: relative;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            max-width: 600px;
            padding: 50px;
            background-color: #fff;
            font-family: 'Inter';
            font-style: normal;
            @media all and (max-width: 768px) {
                padding: 36px 20px;
                border-radius: 12px;
            }
            &__close {
                position: absolute;
                top: 25px;
                right: 25px;
                width: 30px;
                height: 30px;
                background: url(../../assets/PlusGrey.svg);
                background-size: contain;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }
    &__main-info {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }
        &__left-block {
            max-width: 800px;
        }
        &__numbers{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 50px;
            &__item{
                display: flex;
                flex-direction: row;
                width: 310px;
                margin-bottom: 40px;
                margin-right: 50px;
            }
            &__picture{
                width: 50px;
                height: 50px;
                margin-right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.days {
                    background-color: #FFE4F0;
                }
                &.people {
                    background-color: #E2F5F4;
                }
                &.cities {
                    background-color: #FCF3DF;
                }
                &.places {
                    background-color: #EFF2FF;
                }
            }
            &__value {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #273155;
            }
            &__description {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #9FA5AD;

            }
        }
        &__right-block {
            background: #FFFFFF;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.04);
            border-radius: 24px;
            width: 450px;
            min-width: 350px;
            padding: 30px;
            margin: auto 0;
            @media all and (max-width: 768px) {
                width: 100%;
                min-width: 330px;
                padding: 36px 20px 20px;
            }
            &__title {
                font-weight: 700;
                font-size: 22px;
                line-height: 150%;
                color: #273155;
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #3A4256;
                margin: 12px 0px;
            }
            &__personal-info {
                margin-top: 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #707378;

            }
        }
    }
    &__privilege {
        background-color: #F8FBFF;
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }
        &__left-block {
            max-width: 450px;
        }
        &__right-block {
            display: flex;
            flex-wrap: wrap;
            margin-top: 80px;
            @media all and (max-width: 768px) {
                margin-top: 60px;
            }
            &__item {
                width: 220px;
                // height: 90px;
                margin-left: 50px;
                margin-bottom: 50px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-left: 12px;
                    margin-bottom: 30px;
                }
                &__title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    color: #3A4256;
                }
                &__text{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #9FA5AD;
                    margin-top: 5px;
                    // max-height: 70px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    &__program {
        @media all and (min-width: 1651px) {
            background: url(../../assets/ProgramBackground.svg);
            background-position: center;
            background-repeat: no-repeat;
        }
        &__container {
            @media all and (max-width: 1650px) {
                background: url(../../assets/ProgramBackground.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-position-y: 300px;
            }
        }
        &__item {
            display: flex;
            flex-direction: row;
            margin-bottom: 60px;
            &:nth-of-type(2n) {
                flex-direction: row-reverse;
                justify-content: space-between;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                }
                .detailHost__program__item__text-container {
                    margin-left: 0;
                }
            }
            @media all and (max-width: 768px) {
                flex-direction: column;
                margin-bottom: 36px;
            }
            &__picture {
                min-width: 620px;
                height: 350px;
                background-position: center;
                background-size: cover;
                border-radius: 10px;
                @media all and (max-width: 768px) {
                    min-width: 100%;
                    height: 200px;
                    position: relative;
                }
            }
            &__text-container {
                margin: auto 0 auto 70px;
                max-width: 580px;
                @media all and (max-width: 768px) {
                    margin: 0;
                    max-width: 100%;
                }
                &__label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    background: #00A699;
                    border-radius: 4px;
                    max-width: 100px;
                    padding: 3px 0;
                    margin-bottom: 18px;
                    @media all and (max-width: 768px) {
                        display: none;
                    }
                    &.on-picture {
                        display: none;
                       @media all and (max-width: 768px) {
                            position: absolute;
                            display: inherit;
                            padding: 2px 18px;
                            border-radius: 4px 0;
                        } 
                    }
                }
                &__title {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 34px;
                    color: #273155;
                    @media all and (max-width: 768px) {
                        margin: 20px 0 10px;
                    }
                }
                &__description {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #3A4256;
                }
            }

        }
        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }
        &__button {
            width: 260px;
            height: 56px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
            &.margin {
                margin-right: 10px;
                @media all and (max-width: 768px) {
                    margin-right: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &__map {
        background-color: #F8FBFF;
        &__text-container {
            max-width: 500px;
        }
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
            &__map {
                width: 730px;
                height: 400px;
                border-radius: 10px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    height: 200px;
                }
            }
        }
    }
    &__benefits {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__top-container {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 50px;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }
        &__text-container {
            max-width: 500px;
        }
        &__picture-container {
            width: 730px;
            height: 368px;
            border-radius: 10px;
            @media all and (max-width: 768px) {
                width: 100%;
                height: 160px;
                background-size: cover;
            }
        }
        &__items-container {
            display: flex;
            flex-direction: row;
            // align-items: top;
            justify-content: space-between;
            flex-wrap: wrap;
            @media all and (max-width: 768px) {
                // gap: 24px;
            }
            &__item {
                width: 425px;
                // height: 149px;
                padding: 30px;
                display: flex;
                flex-direction: row;
                // gap: 10px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    padding: 0;
                    height: 100%;
                    margin-bottom: 24px;
                }
                &__icon {
                    width: 50px;
                    height: 50px;
                    background: #FFE4F0;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    @media all and (max-width: 768px) {
                        margin-bottom: 10px;
                    }
                    &__picture {
                        width: 24px;
                        height: 24px;
                    }
                }
                &__title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    color: #273155;
                    @media all and (max-width: 768px) {
                        margin-bottom: 6px;
                    }
                }
                &__description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #9FA5AD;
                    max-width: 320px;
                    @media all and (max-width: 768px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    &__accomodations {
        background-color: #F8FBFF;
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
                padding-bottom: 0;
            }
        }
        &__text-container {
            max-width: 650px;
        }
        &__items-container {
            display: flex;
            flex-direction: row;
            margin: 30px 0;
            // gap: 20px;
            align-items: center;
            @media all and(max-width: 768px) {
                overflow: scroll;
            }
        }
        &__type {
            font-weight: 700;
            font-size: 22px;
            line-height: 150%;
            color: #273155;
        }
        &__item {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #333333;
            border-radius: 10px;
            padding: 10px 30px;
            min-width: fit-content;
            border: 1.5px solid #EFF2FF;
            margin-right: 20px;
            text-align: center;
            &:last-of-type{
                margin-right: 0;
            }
            &:hover {
                color: #E61E4D;
                cursor: pointer;
            }
            &__active {
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                color: #FFFFFF;
                &:hover {
                    color: #f18da4;
                }
            }
            @media all and(max-width: 768px) {
                min-width: 140px;
            }
        }
        &__detail {
            max-width: 600px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 40px 30px;
            @media all and(max-width: 768px) {
                padding: 16px;
                height: auto;
            }
            &__title-container {
                display: flex;
                flex-direction: row;
                &__text {
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;
                }
            }
            &__icon {
                width: 60px;
                height: 60px;
                // background: #EFF2FF;
                border-radius: 10px;
                @media all and(max-width: 768px) {
                    width: 50px;
                    height: 50px;
                }
            }
            &__title {
                font-weight: 700;
                font-size: 22px;
                line-height: 150%;
                color: #273155;
                @media all and(max-width: 768px) {
                    font-size: 18px;
                    line-height: 150%;
                }
            }
            &__raiting {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #273155;
                @media all and(max-width: 768px) {
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 5px;
                }
                &__star {
                    width: 15px;
                    height: 15px;
                    margin-left: 10px
                }
            }
        }
    }
    &__activities {
        @media all and(max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                flex-direction: column-reverse;
                padding-bottom: 30px;
            }
        }
        &__text-container {
            align-items: center;
            justify-content: center;
            max-width: 650px;
            margin-left: 60px;
            @media all and(max-width: 768px) {
                margin-left: 0;
            }
        }
        &__button {
            width:270px;
            align-self:flex-start;
            @media all and(max-width: 768px) {
                width: calc(100% - 40px);
                margin: auto;
            }
        }
    }
    &__transports {
        @media all and(max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
        background-color: #F8FBFF;
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                flex-direction: column;
                padding-bottom: 30px;
            }
        }
        &__text-container {
            max-width: 650px;
            margin-right: 60px;
        }
    }
    &__organizer {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 0px;
            @media all and(max-width: 768px) {
                flex-direction: column;
            }
        }
        &__text-container {
            max-width: 500px;
        }
    }
    &__included {
        &__container {
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            @media all and(max-width: 768px) {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        &__items-container {
            display: flex;
            flex-direction: row;
            @media all and(max-width: 768px) {
                flex-direction: column;
            }
        }
        &__item {
            width: 680px;
            margin-right: 30px;
            @media all and(max-width: 768px) {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    &__arrivals{
        @media all and(max-width: 768px) {
                padding: 0 20px;
            }
        &__container{
            min-height: 440px;
            background: url(../../assets/ArrivalsBackground.svg);
            background-position: center;
            background-repeat: no-repeat;
            padding: 20px 0 20px;
            display: flex;
            flex-direction: row;
            color: #FFFFFF;
            border-radius: 10px;
            overflow: hidden;
            // justify-content: space-between;
            @media all and(max-width: 768px) {
                flex-direction: column;
                background: url(../../assets/ArrivalsBackgroundMobile2.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding: 0;
            }
        }
        &__block-description{
            color: #fff;
            margin-bottom: 70px;
            @media all and(max-width: 768px) {
                margin-bottom: 30px
            }
        }
        &__left-block {
            padding: 10px 20px 0px 80px;
            // border-right: 1px solid #EB608D;
            max-width: 750px;
            @media all and(max-width: 768px) {
                padding: 24px;
            }
            &__text {
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                margin-top: 16px;
                max-width: 430px;
            }
        }
        &__right-block {
            padding: 50px 0 0px 70px;
            display: flex;
            flex-direction: column;
            @media all and(max-width: 768px) {
                padding: 24px;
            }
            &__title {
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                letter-spacing: -0.02em;
                margin-bottom: 15px;
            }
            &__value {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                margin-top: 20px;
            }
            &__price {
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                margin-top: 120px;
                @media all and(max-width: 768px) {
                    font-size: 24px;
                    line-height: 29px;
                    margin-top: 40px;
                }
                &-full {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #C3C8CC;
                    text-decoration-line: line-through;
                    margin-left: 15px;
                    margin-right: 5px;
                    @media all and(max-width: 768px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
                &__sale {
                    background: #FF1B1B;
                    border-radius: 2px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                    padding: 1px 5px;
                }
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                margin-top: 10px;
                max-width: 320px;
            }
        }
    }
    &__reviews {
        &__container {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            // height: 1000px;
        }
        &__items-container {
            // margin-top: 50px;
            display: flex;
            flex-direction: row;
            margin: 0 0 40px;
            // flex-wrap: wrap;
            // gap: 20px;
            @media all and(max-width: 768px) {
                width: 100%;
                overflow: scroll;
            }
        }
        // &__buttons {
        //     display: flex;
        //     flex-direction: row;
        //     margin: 50px auto 0;
        //     gap: 20px;
        //     @media all and(max-width: 768px) {
        //         flex-direction: column;
        //         margin: 20px 0 0;
        //     }
        // }
        // &__button {
        //     width: 300px;
        //     height: 52px;
        //     @media all and(max-width: 768px) {
        //         width: 100%;
        //     }
        // }
    }
    &__photos {
        &__container {
            padding-bottom: 30px;
            @media all and(max-width: 768px) {
                padding-bottom: 0;
            }
        }
    }
    &__questions {
        &__items {
            width: 1200px;
            @media all and(max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__download-form {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 20px 0 80px;
            background: url(../../assets/Airplane2.svg);
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.04);
            border-radius: 24px;
            background-repeat: no-repeat;
            background-position-x: 430px;
            background-position-y: 10px;
            // background: #F8FBFF;
            @media all and(max-width: 768px) {
                flex-direction: column;
                padding: 24px 40px;
                background: url(../../assets/AirplaneMini.svg);
                background-repeat: no-repeat;
                background-position-x: 250px;
                background-position-y: 120px;
            }
        }
        &__text {
            display: flex;
            flex-direction: column;
            max-width: 500px;
            padding: 60px 0 24px;
            @media all and(max-width: 768px) {
                padding: 0;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 22px;
            line-height: 150%;
            color: #273155;
        }
        &__description {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #3A4256;
            margin: 12px auto 20px;
        }
        &__form {
            width: 430px;
            // height: 510px;
            border-radius: 24px;
            padding: 0 0 60px;
            display: flex;
            flex-direction: column;
            @media all and(max-width: 768px) {
                width: 100%;
                padding: 30px 0 0;
            }
        }
        &__personal-info {
            // margin-top: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #707378;
            margin-top: 12px;
            @media all and(max-width: 768px) {
                margin-top: 10px;
            }
        }
        &__button-container {
            display: flex;
            flex-direction: column;
            @media all and(max-width: 768px) {
                flex-direction: column;
            }
        }
        &__button {
            width: 100%;
            // margin: 5px 15px 0 0;
            @media all and(max-width: 768px) {
                width: 100%;
                margin: 0;
            }
        }
    }
    .download-link {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 94px;
        border-bottom: 1px solid #E5E6E9;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        margin: 0 32px;
        @media all and (min-width: 1464px) {
            margin: auto;
            max-width: 1280px;
        }
        &__main-logo {
            width: 270px;
            height: 30px;
            background: url(../../assets/Main-logo.svg);
            background-size: cover;
            background-position: center;
            cursor: pointer
        }
        &__title {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__logo {
                width: 200px;
                height: 50px;
                background: url(../../assets/TravelApp.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer
            }
            &__powered {
                width: 200px;
                height: 16px;
                background: url(../../assets/Powered.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer
            }
        }
        &__center-container {
            display: flex;
            flex-direction: row;
            // gap: 30px;
            &__item {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #686F88;
                cursor: pointer;
                margin-right: 30px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        &__rigth-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__phone {
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                color: #333333;
                margin-right: 20px;
            }
            &__button {
                width: 200px;
                height: 56px;
            }
        }
        &__mobile {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            align-items: center;
            background: #F8F7FA;
            border-bottom: 1px solid #E5E6E9;
            &__menu {
                padding: 24px 20px 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-width: 300px;
                .v-list-item {
                    padding: 0;
                }
                &__button {
                    width: 260px;
                }
            }
            &__title {
                width: 180px;
                height: 20px;
                background: url(../../assets/Main-logo.svg);
                background-size: cover;
                background-position: center;
                cursor: pointer;
            }
            &__button {
                width: 40px;
                height: 40px;
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                cursor: pointer;
                &__icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    &__footer {
        padding: 0 32px;
        &__links {
            width: 100%;
            padding: 40px 0 0;
            padding-bottom: 50px;
            @media all and ( min-width: 1400px) {
                margin: 40px auto 0;
                max-width: 1280px;
            }
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: space-between;
                margin: 40px 0 0;
                padding: 0;
                height: auto;
            }
            &__left-container {
                display: flex;
                flex-direction: row;
                gap: 70px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    gap: 15px;
                    margin-bottom: 50px;
                }
            }
            &__contacts {
                text-align: left;
                &__key {
                    font-weight: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #273155;
                    margin-bottom: 8px;
                }
                &__value {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 115%;
                    color: #273155;
                    > a {
                        text-decoration: none;
                        color: #273155 !important;
                    }
                }
                &__social {
                    display: flex;
                    flex-direction: row;
                    &__item {
                        width: 32px;
                        height: 32px;
                        background-position: center;
                        background-repeat: no-repeat;
                        border: 1px solid #93A3B4;
                        border-radius: 50%;
                        box-sizing: border-box;
                        margin-right: 12px;
                        margin-top: 15px;
                        cursor: pointer;
                        &:last-of-type {
                            margin-right: none;
                        }
                        &.facebook {
                            background-image: url(../../assets/Facebook.svg);
                        }
                        &.VK{
                            background-image: url(../../assets/VK.svg);
                        }
                        &.telegram {
                            background-image: url(../../assets/Telegram.svg);
                            background-position: 6px 8px;
                        }
                        &.instagram {
                            background-image: url(../../assets/Instagram.svg);
                        }
                    }
                }
            }
            &__right-container {
                display: flex;
                flex-direction: row;
                // gap: 50px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    // gap: 28px;
                    margin-bottom: 30px;
                }
                &__item {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: -0.02em;
                    color: #686F88;
                    cursor: pointer;
                    margin-right: 50px;
                    &:last-of-type {
                        margin-right: 0;
                    }
                    @media all and (max-width: 768px) {
                        margin-bottom: 28px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &__static {
            width: 100%;
            max-width: 1400px;
            height: 62px;
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: space-between;
            border-top: 1px solid #EBEBEB;
            opacity: 0.5;
            @media all and (max-width: 768px) {
                flex-direction: column;
                height: auto;
                padding: 24px 0;
                // gap: 15px;
            }
            &__item {
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                display: flex;
                align-items: center;
                color: #505872;
                @media all and (max-width: 768px) {
                    margin-bottom: 15px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            // &__affetta {
            //     display: flex;
            //     flex-direction: row;
            // }
        }
    }
}
.only-arrow {
    margin-top: 20px;
    .theme--light.v-pagination .v-pagination__item {
        display: none;
    }
}
.only-circle {
    .theme--light.v-pagination .v-pagination__navigation {
        display: none;
    }
}
</style>
