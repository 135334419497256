<template>
    <div class="review-big">
        <div v-if="review" class="review-big__user__avatar" :style="`background-image: url(${review ? imageSrc(review.photo) : ''})`"></div>
        <div v-else class="review-big__user__avatar"></div>
        <div class="review-big__user">
            <div class="review-big__user__name">
                <template v-if="review">
                    {{ review.firstname }} {{ review.lastname }}
                </template>
            </div>
            <!-- <div class="review-big__time">{{ review.date | moment('DD.MM.YYYY') }}</div> -->
            <!-- <div style="display: flex; align-items: center; margin-top: 5px;">
                <div class="review-big__user__raiting">{{ review.rating.overall }}</div>
                <v-rating
                    :value="review.rating.overall"
                    background-color="grey"
                    color="warning"
                    half-increments
                    hover
                    length="5"
                    size="15"
                    dense
                    readonly
                    style="margin-left: 5px"
                />
            </div> -->
        </div>
        <div class="review-big__text">{{ review.review }}</div>
        <div class="review-big__button" @click="openPopup">Читать отзыв полностью</div>
        <v-dialog 
            v-model="shownReview"
            max-width="850px"
        >
            <div class="review-big__popup">
                <div class="review-big__popup__close" @click="closePopup"></div>
                <div class="review-big__popup__container">
                    <div class="review-big__popup__avatar" :style="`background-image: url(${review ? imageSrc(review.photo) : ''})`"></div>
                    <div class="review-big__popup__info">
                        <!-- <div class="review-big__popup__info__raiting-container">
                            <div class="review-big__popup__info__raiting">{{ review.rating.overall }}</div>
                            <v-rating
                                :value="review.rating.overall"
                                background-color="grey"
                                color="warning"
                                half-increments
                                hover
                                length="5"
                                size="15"
                                dense
                                readonly
                                style="margin-left: 5px"
                            />
                        </div> -->
                        <!-- <div class="review-big__popup__info__company">
                            <div class="review-big__popup__info__trips__title">Компания</div>
                            <div v-if="review.user && review.user.company" class="review-big__popup__info__trips__items">{{ review.user.company }}</div>
                        </div>
                        <div class="review-big__popup__info__trips">
                            <div class="review-big__popup__info__trips__title">Путешествия</div>
                            <div class="review-big__popup__info__trips__items">Колумбия, Перу, Байкал</div>
                        </div> -->
                        <div v-if="isDesktop" class="review-big__popup__info__social">
                            <!-- <a
                                v-if="review.user && review.user.fb"
                                :href="pageTemplate.contacts.fb"
                                target="_blank">
                                <div class="review-big__popup__info__social__item facebook"></div>
                            </a> -->
                            <a
                                v-if="review && review.social"
                                :href="review.social"
                                target="_blank">
                                <div class="review-big__popup__info__social__item VK"></div>
                            </a>
                            <!-- <a
                                v-if="review.user && review.user.telegram"
                                :href="review.user.telegram"
                                target="_blank">
                                <div class="review-big__popup__info__social__item telegram"></div>
                            </a> -->
                            <!-- <a
                                v-if="review.user && review.user.instagram"
                                :href="pageTemplate.contacts.instagram"
                                target="_blank">
                                <div class="review-big__popup__info__social__item instagram"></div>
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="review-big__popup__name">
                    <template v-if="review">
                        {{ review.firstname }} {{ review.lastname }}
                    </template>
                </div>
                <div class="review-big__popup__review">{{ review.review }}</div>
                <div v-if="!isDesktop" class="review-big__popup__info__social">
                            <!-- <a
                                v-if="review.user && review.user.fb"
                                :href="pageTemplate.contacts.fb"
                                target="_blank">
                                <div class="review-big__popup__info__social__item facebook"></div>
                            </a> -->
                            <a
                                v-if="review && review.social"
                                :href="review.social"
                                target="_blank">
                                <div class="review-big__popup__info__social__item VK"></div>
                            </a>
                            <!-- <a
                                v-if="review.user && review.user.telegram"
                                :href="review.user.telegram"
                                target="_blank">
                                <div class="review-big__popup__info__social__item telegram"></div>
                            </a> -->
                            <!-- <a
                                v-if="review.user && review.user.instagram"
                                :href="pageTemplate.contacts.instagram"
                                target="_blank">
                                <div class="review-big__popup__info__social__item instagram"></div>
                            </a> -->
                        </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
    import { imageSrc } from '@/helpers';

    export default {
        name: 'Review',
        props: {
            review: {
                type: Object,
                default: () => {}
            },
            isDesktop: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            shownReview: false,
        }),
        computed: {
        },
        methods: {
            openPopup() {
                this.shownReview = true
            },
            closePopup() {
                this.shownReview = false
            },
        }
    }
</script>


<style lang="scss">
.review-big {
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.04);
    border-radius: 12px;
    width: 305px;
    margin-right: 20px;
    &:last-of-type {
        margin-right: 0;
    }
    @media all and(max-width: 768px) {
        // padding: 20px;
        min-width: 280px;
        height: auto;
    }
    // &__time{
    //     margin-left: 12px;
    //     font-size: 16px;
    //     line-height: 150%;
    //     letter-spacing: -0.02em;
    //     color: #93A3B4;
    // }
    &__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        @media all and(max-width: 768px) {
            margin-bottom: 15px;
        }
        &__avatar{
            width: 305px;
            height: 360px;
            border-radius: 12px 12px 0px 0px;
            background-color: #F6F7F9;
            background: url(../../assets/UserDefaultAvatar.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            // margin-right: 20px;
            @media all and(max-width: 768px) {
                width: 100%;
            }
        }
        &__name {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #3A4256;
            margin-top: 5px;
        }
        &__raiting {
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #707378;
            margin-top: 5px;
        }
    }
    &__text{
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        height: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        // -webkit-line-clamp: 50;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    &__button {
        margin: 20px auto 0 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #2D9CDB;
        border-bottom: 1px dashed #2D9CDB;
        cursor: pointer;
    }
    &__popup {
        position: relative;
        width: 850px;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 50px 80px 60px 50px;
        @media all and(max-width: 768px) {
            width: 100%;
            padding: 0;
        }
        &__container {
            display:flex; 
            flex-direction: row;
            @media all and(max-width: 768px) {
                flex-direction: column;
            }
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__avatar {
            width: 300px;
            height: 300px;
            border-radius: 12px;
            background-position: top;
            background-size: cover;
            @media all and(max-width: 768px) {
                width: 100%;
                height: 380px;
            }
        }
        &__info {
            margin-left: 30px;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                margin: 20px 20px 0;
                height: 150px;
                justify-content: space-around;
            }
            &__raiting-container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            &__raiting{
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #707378;
            }
            &__company {
                // margin-top: 70px;
            }
            &__trips {
                // margin-top: 70px;
                &__title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #273155;
                }
                &__items {
                    margin-top: 12px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #707378;
                    @media all and(max-width: 768px) {
                        margin-top: 4px;
                    }
                }
            }
            &__social {
                display: flex;
                flex-direction: row;
                @media all and(max-width: 768px) {
                    padding: 0 20px 20px;
                }
                &__item {
                    width: 32px;
                    height: 32px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid #93A3B4;
                    border-radius: 50%;
                    box-sizing: border-box;
                    margin-right: 12px;
                    margin-top: 15px;
                    cursor: pointer;
                    @media all and(max-width: 768px) {
                        margin-top: 0;
                    }
                    &:last-of-type {
                        margin-right: none;
                    }
                    &.facebook {
                        background-image: url(../../assets/Facebook.svg);
                    }
                    &.VK{
                        background-image: url(../../assets/VK.svg);
                    }
                    &.telegram {
                        background-image: url(../../assets/Telegram.svg);
                        background-position: 6px 8px;
                    }
                    &.instagram {
                        background-image: url(../../assets/Instagram.svg);
                    }
                }
            }
        }
        &__name {
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
            color: #273155;
            margin-top: 30px;
            @media all and(max-width: 768px) {
                margin-top: 20px;
                font-size: 20px;
                line-height: 24px;
                padding: 0 20px;
            }
        }
        &__review {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #273155;
            margin-top: 20px;
            @media all and(max-width: 768px) {
                font-size: 14px;
                line-height: 26px;
                padding: 0 20px 20px;
            }
        }
    }
}
</style>